/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"

type ProjectCardProps = {
  link: string
  title: string
  children: React.ReactNode
  bg: string
  txtTransform?: string
}

const ProjectCard = ({ title, children, bg, txtTransform }: ProjectCardProps) => (
  // <a
  //   href={link}
  //   target="_blank"
  //   rel="noreferrer noopener"
  //   sx={{
  //     display: [`inline`, `inline`, `inline`, `inline`, `flex`],
  //     alignItems: `center`,
  //     textAlign: [`center`, `center`, `center`, `center`, `left`],
  //     width: `100%`,
  //     boxShadow: `lg`,
  //     position: `relative`,
  //     textDecoration: `none`,
  //     borderRadius: `lg`,
  //     px: 4,
  //     py: [3, 4],
  //     color: `white`,
  //     background: bg || `none`,
  //     transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
  //     "&:hover": {
  //       color: `white !important`,
  //       transform: `translateY(-7px)`,
  //       boxShadow: `xl`,
  //     },
  //   }}
  // >
  <div
    sx={{
      display: [`inline`, `inline`, `inline`, `inline`, `flex`],
      alignItems: `center`,
      textAlign: [`center`, `center`, `center`, `center`, `left`],
      width: `100%`,
      boxShadow: `lg`,
      position: `relative`,
      textDecoration: `none`,
      borderRadius: `lg`,
      px: 4,
      py: [3, 4],
      color: `white`,
      background: bg || `none`,
      transition: `all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275) !important`,
      "&:hover": {
        color: `white !important`,
        transform: `translateY(-7px)`,
        boxShadow: `xl`,
      },
    }}
  >
    <div
      sx={{
        textTransform: txtTransform || `uppercase`,
        letterSpacing: `wide`,
        fontSize: [4, 5],
        fontWeight: `medium`,
        lineHeight: 1,
        color: `#fec10d`,
        width: [`full`, `full`, `full`, `full`, `full`, `2/3`]
      }}
    >
      {title}
    </div>
    <div sx={{
        opacity: 0.85,
        textShadow: `0 2px 10px rgba(0, 0, 0, 0.3)`,
        width: [`full`, `full`, `full`, `full`, `full`, `2/3`],
        pt: 2,
        px: [0, 0, 0, 3, 3]
      }}
    >
      {children}
    </div>
  </div>
)

export default ProjectCard